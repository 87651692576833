import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { IInsta } from "./insta";

@Injectable({
    providedIn: "root",
})
export class InstaService {
    public instas$: Observable<IInsta[]>;

    // all instas
    public instas: IInsta[];
    public instasMap: Map<number, IInsta>;
    private readonly instasSource = new BehaviorSubject<IInsta[]>([]);

    public constructor() {
        this.instas$ = this.instasSource.asObservable();
        this.instas$.subscribe((instas) => {
            this.instas = instas;
            this.instasMap = getInstasMap(instas);
        });
    }

    public setInstas(instas: IInsta[]): void {
        this.instasSource.next([...instas]);
    }

    public isNotExternal(link: string) {
        const pattern = /^((http|https|ftp):\/\/)/;

        return !pattern.test(link);
    }
}

function getInstasMap(instas: IInsta[]): Map<number, IInsta> {
    return new Map(instas.map((insta) => [insta.id, insta]));
}
