<div *ngIf="type === 'classic'" class="button">
    <span>{{ title }}</span>
</div>

<div *ngIf="type === 'plain'" class="button plain-button">
    <span>{{ title }}</span>
</div>

<div *ngIf="type === 'back'" class="back-button">
    <div class="circle"></div>
    <span>{{ title }}</span>
</div>

<div *ngIf="type === 'play'" class="play-button">
    <span>Jouer</span>
</div>

<div *ngIf="type === 'discover'" class="discover-button">
    <span>Découvrir</span>
</div>
