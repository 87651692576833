import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";

import { environment } from "@environment";

import { IdleService } from "../idle/idle.service";

import { IVideo } from "@models/video/video";

import videojs from "video.js";

export const playerOptions = {
    autoplay: false,
    controls: false,
    fluid: true,
    muted: true,
    preload: "none",
    responsive: false,
    sources: [],
};

const BTN_STATE = {
    PAUSE: "Pause",
    PLAY: "Lecture",
};

@Injectable({ providedIn: "root" })
export class PlayerService implements OnDestroy {
    public player: videojs.Player;

    public playerState = false;

    public currentVideo$: Observable<IVideo | null>;
    public relatedVideo$: Observable<IVideo[]>;

    public playPauseBtnTitle = BTN_STATE.PLAY;

    public playerDisplay$: Observable<boolean>;
    private readonly currentVideoSource = new BehaviorSubject<IVideo | null>(null);

    private relatedVideosArray: IVideo[];
    private readonly relatedVideoSource = new BehaviorSubject<IVideo[]>([]);
    private readonly mediaPath = `${environment.moustacheApiUrl}/${environment.mediaAssetsPath}/`;
    private readonly playerDisplaySource = new BehaviorSubject<boolean>(false);

    private playlistSize: number = 0;
    private playlistPosition: number = 0;

    private readonly subscriptions = new Subscription();

    public constructor(private readonly idleService: IdleService) {
        this.playerDisplay$ = this.playerDisplaySource.asObservable();

        this.currentVideo$ = this.currentVideoSource.asObservable();
        this.relatedVideo$ = this.relatedVideoSource.asObservable();
        this.relatedVideo$.subscribe((rVideos) => (this.relatedVideosArray = rVideos));
    }

    public initPlayer() {
        this.setCurrentVideo(null);
        this.playerState = false;
        this.playPauseBtnTitle = BTN_STATE.PLAY;
        this.idleService.setPause(false);
    }

    public playEnded() {
        // check if there is a next video
        if (this.playlistPosition < this.playlistSize - 1) {
            this.playlistPosition++;
            const nextVideo = this.relatedVideosArray[this.playlistPosition];
            if (nextVideo != null) {
                this.changePlay(nextVideo);
            }
        } else {
            this.initPlayer();
        }
    }

    public playVideo() {
        this.playerState = true;
        this.playPauseBtnTitle = BTN_STATE.PAUSE;
        this.player.play();
        this.idleService.setPause(true);
    }

    public pauseVideo() {
        this.playerState = false;
        this.playPauseBtnTitle = BTN_STATE.PLAY;
        this.player.pause();
        this.idleService.setPause(false);
    }

    public changePlay(video: IVideo) {
        this.setCurrentVideo(video);
        this.player.src({ type: "video/mp4", src: `${this.mediaPath}${video.url}` });
        const posPlaylist = this.relatedVideosArray.findIndex((rVideo: IVideo) => rVideo.id === video.id);
        this.playlistPosition = posPlaylist;

        // manual let service play the video
        this.playVideo();
    }

    public setCurrentVideo(video: IVideo | null) {
        this.currentVideoSource.next(video);
    }

    public setRelatedVideos(videos: IVideo[]) {
        this.relatedVideoSource.next([...videos]);
        this.playlistSize = videos.length;
    }

    public show(video: IVideo, relatedVideos: IVideo[]) {
        /* tslint:disable */
        // this action is needed to call the good context in player callback
        const service = this;
        /* tslint:enable */

        this.setCurrentVideo(video);
        this.setRelatedVideos(relatedVideos);
        this.playerDisplaySource.next(true);
        // wait a second for DOM change
        setTimeout(() => {
            // instantiate Video.js
            this.player = videojs(document.getElementById("mainvideo"), playerOptions);
            this.player.src({ type: "video/mp4", src: `${this.mediaPath}${video.url}` });
            this.player.ready(() => {
                // manual let service play the video
                service.playVideo();
                // when it's finish reset player button state
                service.player.on("ended", function () {
                    service.playEnded();
                });
            });
        }, 100);
    }

    public hide() {
        // just in case stop video and restart screensaver
        this.idleService.setPause(false);

        // hide the modal
        this.playerDisplaySource.next(false);

        // destroy player
        if (this.player != null) {
            this.player.dispose();
        }
    }

    public ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
