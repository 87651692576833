export enum EnvironmentType {
    PROD = "prod",
    PREPROD = "preprod",
    TESTING = "testing",
    DEV = "dev",
}

export interface IEnvironment {
    enableMenu: boolean;
    gameServerUrl: string;
    inatictivityTime: number;
    mapServerUrl: string;
    mediaAssetsPath: string;
    mediaInstagramAssetsPath: string;
    moustacheApiUrl: string;
    paymentsUrl: string;
    production: boolean;
    siteAssetsUrl: string;
    swiperDelay: number;
    type: EnvironmentType;
}
