import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { IInstaGetDto } from "@dtos";
import { environment } from "@environment";
import { IInsta } from "@models";
import { InstaMapper } from "./insta.mapper";

@Injectable()
export class InstaRepository {
    public constructor(
        private readonly http: HttpClient,
        private readonly instaMapper: InstaMapper,
    ) {}

    // get all instas
    public getInstas(): Observable<IInsta[]> {
        const dataSource = this.http.get<IInstaGetDto[]>(`${environment.moustacheApiUrl}/instas`);

        return dataSource.pipe(
            map((instas) => instas.map(this.instaMapper.mapGetInsta)),
            catchError(() => of([])),
        );
    }
}
