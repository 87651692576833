import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";

import { DragDropModule } from "@angular/cdk/drag-drop";

import { KeyboardComponent } from "./keyboard/keyboard.component";

@NgModule({
    declarations: [KeyboardComponent],
    exports: [KeyboardComponent],
    imports: [CommonModule, DragDropModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class KeyboardModule {}
