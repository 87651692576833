import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
@Injectable({ providedIn: "root" })
export class IframeService {
    public eventMsg$: Observable<string>;
    public display$: Observable<boolean>;
    public iframe$: Observable<HTMLIFrameElement | null>;

    private readonly eventMsgSource = new BehaviorSubject<string>("");
    private readonly displaySource = new BehaviorSubject<boolean>(false);
    private readonly iframeSource = new Subject<HTMLIFrameElement>();

    public constructor() {
        this.eventMsg$ = this.eventMsgSource.asObservable();
        this.display$ = this.displaySource.asObservable();
        this.iframe$ = this.iframeSource.asObservable();

        this.manageMessageHandler();
    }

    public isShown() {
        return this.displaySource.value;
    }

    public show(uri: string) {
        const iframe = prepareFrame(uri);
        this.displaySource.next(true);
        setTimeout(() => {
            this.iframeSource.next(iframe);
        }, 100);
    }

    public hide() {
        this.displaySource.next(false);
        this.eventMsgSource.next("");
    }

    private manageMessageHandler() {
        // tslint:disable
        const self = this;
        // tslint:enable

        // handle message from outside
        window.onmessage = (e: any) => {
            self.eventMsgSource.next(e.data);
        };
    }
}

function prepareFrame(src: string): HTMLIFrameElement {
    const ifrm = document.createElement("iframe");
    ifrm.setAttribute("src", src);
    ifrm.setAttribute("target", "_parent");
    ifrm.setAttribute("frameBorder", "0");
    ifrm.setAttribute("marginwidth", "0");
    ifrm.setAttribute("marginheight", "0");
    ifrm.style.backgroundColor = "#fff";
    ifrm.style.width = "100vw";
    ifrm.style.height = "1656px";
    ifrm.style.position = "fixed";

    return ifrm;
}
