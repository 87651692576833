import { Injectable } from "@angular/core";

import { IInstaGetDto } from "@dtos";
import { IInsta } from "@models";

@Injectable()
export class InstaMapper {
    public mapGetInsta = (instaDto: IInstaGetDto): IInsta => {
        return { ...instaDto };
    };
}
