import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ButtonComponent, FilterComponent, HeaderComponent, VideoComponent } from "./components";

import { MediaDispatcherPipe, MyCurrencyPipe } from "./pipes";

@NgModule({
    bootstrap: [],
    declarations: [
        ButtonComponent,
        MediaDispatcherPipe,
        MyCurrencyPipe,
        FilterComponent,
        HeaderComponent,
        VideoComponent,
    ],
    exports: [ButtonComponent, MediaDispatcherPipe, MyCurrencyPipe, FilterComponent, HeaderComponent, VideoComponent],
    imports: [CommonModule],
    providers: [],
})
export class SharedModule {}
