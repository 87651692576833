<ng-container *ngIf="menuService.menu$ | async as param">
    <div class="header" [ngClass]="param.display ? 'header--show' : 'header--hide'">
        <div class="logout" (click)="goToBack()"></div>
        <span *ngIf="param.hasIcon === ''" class="title">
            {{ param.currentSection }}
        </span>
        <span *ngIf="param.hasIcon !== ''" class="title">
            <img alt="icon" src="{{ param.hasIcon }}" />
        </span>
    </div>

    <div class="container" [ngClass]="param.display ? 'container--show' : 'container--hide'">
        <nav>
            <input
                type="radio"
                id="item-1"
                class="item-1"
                [ngClass]="param.activeTab === 1 ? 'selected' : ''"
                checked
                name="menu"
                (click)="displayOqp()"
            />
            <label for="item-1">
                <span>
                    <img
                        [ngClass]="param.activeTab !== 1 ? 'show' : 'hide'"
                        src="assets/img/tabbar/location.svg"
                        alt="opq"
                    />
                    <img
                        [ngClass]="param.activeTab === 1 ? 'show' : 'hide'"
                        src="assets/img/tabbar/location-active.svg"
                        alt="opq"
                    />
                </span>
            </label>

            <input
                type="radio"
                id="item-2"
                class="item-2"
                [ngClass]="param.activeTab === 2 ? 'selected' : ''"
                name="menu"
                (click)="displayGame()"
            />
            <label for="item-2">
                <span>
                    <img
                        [ngClass]="param.activeTab !== 2 ? 'show' : 'hide'"
                        src="assets/img/tabbar/game.svg"
                        alt="game"
                    />
                    <img
                        [ngClass]="param.activeTab === 2 ? 'show' : 'hide'"
                        src="assets/img/tabbar/game-active.svg"
                        alt="game"
                    />
                </span>
            </label>

            <input
                type="radio"
                id="item-3"
                class="item-3"
                [ngClass]="param.activeTab === 3 ? 'selected' : ''"
                name="menu"
                (click)="displayInsta()"
            />
            <label for="item-3">
                <span>
                    <img
                        [ngClass]="param.activeTab !== 3 ? 'show' : 'hide'"
                        src="assets/img/tabbar/saveurs.svg"
                        alt="saveurs"
                    />
                    <img
                        [ngClass]="param.activeTab === 3 ? 'show' : 'hide'"
                        src="assets/img/tabbar/saveurs-active.svg"
                        alt="saveurs"
                    />
                </span>
            </label>

            <div class="cursor">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.625 18.625" height="19.867" width="19.867">
                    <path d="M0 0v18.625C.459 6.493 7.17.804 18.625 0z" fill-rule="evenodd" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.625 18.625" height="19.867" width="19.867">
                    <path d="M0 0v18.625C.459 6.493 7.17.804 18.625 0z" fill-rule="evenodd" />
                </svg>
            </div>
        </nav>
        <div class="footer--section">
            <div class="footer--section__item">Où partir</div>
            <div class="footer--section__item">Jeux</div>
            <div class="footer--section__item">Marco Vasco Expérience</div>
        </div>
    </div>
</ng-container>
