<ng-container *ngIf="playerService.playerDisplay$ | async as state">
    <div class="player" [ngClass]="state ? 'player--show' : 'player--hide'">
        <div class="player__content__main">
            <div class="player__content__main--left">
                <div class="controller">
                    <fumanbrush-button type="plain" title="Fermer" (click)="close()"></fumanbrush-button>
                    <br />
                    <fumanbrush-button
                        type="plain"
                        title="{{ playerService.playPauseBtnTitle }}"
                        (click)="togglePlay()"
                    ></fumanbrush-button>
                </div>
                <div class="playlist">
                    <ng-container *ngIf="playerService.relatedVideo$ | async as videos">
                        <div class="playlist--item" *ngFor="let video of videos; trackBy: trackById">
                            <fumanbrush-video
                                [item]="video"
                                [current$]="playerService.currentVideo$"
                                size="small"
                                (click)="playVideo(video)"
                            >
                            </fumanbrush-video>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="player__content__main--right">
                <video id="mainvideo" class="video-js"></video>
                <ng-container *ngIf="currentVideo != null">
                    <div class="played__video--title">
                        {{ currentVideo.title }}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
