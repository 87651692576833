<div class="content">
    <router-outlet> </router-outlet>

    <!-- iframe displayer -->
    <fumanbrush-iframe></fumanbrush-iframe>

    <!-- tab menu -->
    <fumanbrush-menu *ngIf="enableMenu"></fumanbrush-menu>

    <!-- popup -->
    <fumanbrush-popup></fumanbrush-popup>

    <!-- videi player -->
    <fumanbrush-player></fumanbrush-player>

    <mv-screensaver
        device="fumanchu"
        mode="manual"
        title="Envie de plonger dans l’univers MARCO VASCO ? C’est par ici !"
    >
    </mv-screensaver>
    <mv-vkeyboard></mv-vkeyboard>
</div>
