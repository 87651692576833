import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AppRoutes } from "./routes";

import { environment } from "@environment";

const routes: Routes = [
    {
        loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
        path: AppRoutes.HOME,
    },
    {
        loadChildren: () => import("./oqp/oqp.module").then((m) => m.OqpModule),
        path: AppRoutes.OQP,
    },
    {
        loadChildren: () => import("./game/game.module").then((m) => m.GameModule),
        path: AppRoutes.GAME,
    },
    {
        loadChildren: () => import("./insta/insta.module").then((m) => m.InstaModule),
        path: AppRoutes.INSTA,
    },
    {
        path: "**",
        redirectTo: environment.enableMenu ? AppRoutes.HOME : AppRoutes.INSTA,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes, { useHash: true })],
})
export class AppRoutingModule {}
