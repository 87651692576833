import { Component, TrackByFunction, ViewChild } from "@angular/core";

import { PlayerService } from "../player.service";

import { IVideo } from "@models";

@Component({
    selector: "fumanbrush-player",
    styleUrls: ["./player.component.scss"],
    templateUrl: "./player.component.html",
})
export class PlayerComponent {
    public currentVideo: IVideo | null;

    public constructor(public readonly playerService: PlayerService) {
        this.playerService.currentVideo$.subscribe((video) => {
            this.currentVideo = video;
        });
    }

    public togglePlay() {
        if (this.playerService.playerState) {
            this.playerService.pauseVideo();
        } else {
            this.playerService.playVideo();
        }
    }

    public playVideo(video: IVideo) {
        this.playerService.changePlay(video);
    }

    public trackById: TrackByFunction<IVideo> = (index: number, video: IVideo): any => {
        return video.id;
    };

    public close() {
        this.playerService.hide();
    }
}
