import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { NavigationService } from "@models";

export const SECTION = {
    DISCOVER: "Comptoir des saveurs",
    GAME: "Jeux",
    OQP: "Où partir",
    INSTA: "Marco Vasco Expérience",
};

export interface IMenu {
    activeTab: number;
    currentSection: string;
    display: boolean;
    hasIcon: string;
}

@Injectable({ providedIn: "root" })
export class MenuService {
    public menu$: Observable<IMenu>;

    private readonly menuSource = new BehaviorSubject<IMenu>({
        activeTab: 1,
        currentSection: SECTION.OQP,
        display: false,
        hasIcon: "",
    });

    public constructor(private readonly navigationService: NavigationService) {
        this.menu$ = this.menuSource.asObservable();
    }

    public goToHome() {
        this.navigationService.goToHome();
    }

    public showForOqp() {
        this.menuSource.next({
            activeTab: 1,
            currentSection: SECTION.OQP,
            display: true,
            hasIcon: "",
        });
        this.navigationService.goToOqp();
    }

    public showForGame() {
        this.menuSource.next({
            activeTab: 2,
            currentSection: SECTION.GAME,
            display: true,
            hasIcon: "",
        });
        this.navigationService.goToGame();
    }

    public showForInsta() {
        this.menuSource.next({
            activeTab: 3,
            currentSection: SECTION.INSTA,
            display: true,
            hasIcon: "",
        });
        this.navigationService.goToInsta();
    }

    public hide() {
        this.menuSource.next({
            activeTab: 1,
            currentSection: SECTION.OQP,
            display: false,
            hasIcon: "",
        });
    }
}
