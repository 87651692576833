import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class KeyboardService {
    public value: string;

    public display = false;

    public keyboardData$: Observable<string>;
    public keyboardPressedData$: Observable<string>;

    // handle ui-kit keyboard
    private keyboard: HTMLElement | any;
    private readonly keyboardDataSource = new BehaviorSubject<string>("");
    private readonly keyboardPressedDataSource = new BehaviorSubject<string>("");

    constructor() {
        // create an observable for keyboard input
        this.keyboardData$ = this.keyboardDataSource.asObservable();
        this.keyboardPressedData$ = this.keyboardPressedDataSource.asObservable();

        this.setAutoClose();
    }

    public setAutoClose() {
        // console.log("auto close");
    }

    public init() {
        if (document.querySelector("mv-keyboard") != null) {
            this.keyboard = document.querySelector("mv-keyboard");
            this.keyboard.addEventListener("keyboard-input-changed", (e: any) => {
                this.dispatchManager(e.detail.value);
            });
            this.keyboard.addEventListener("keyboard-button-pressed", (e: any) => {
                this.keyboardPressedDataSource.next(e.detail.value);
            });
        }
    }

    public reset() {
        this.clear();
        this.hide();
    }

    public destroy() {
        this.keyboard.destroy();
    }

    public setInput(input: string) {
        this.keyboard.setInput(input);
        this.dispatchManager(input);
    }

    public show() {
        this.display = true;
    }

    public hide() {
        this.display = false;
    }

    public clear() {
        this.keyboard.setInput("");
        this.dispatchManager("");
    }

    protected dispatchManager(input: string) {
        this.keyboardDataSource.next(input);
    }
}
