import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ApiBindingModule } from "@api-binding/api-binding.module";
import { IframeModule } from "@core/iframe/iframe.module";
import { KeyboardModule } from "@core/keyboard/keyboard.module";
import { MenuModule } from "@core/menu/menu.module";
import { PlayerModule } from "@core/player/player.module";
import { PopupModule } from "@core/popup/popup.module";

@NgModule({
    declarations: [],
    exports: [IframeModule, KeyboardModule, MenuModule, PlayerModule, PopupModule],
    imports: [CommonModule, ApiBindingModule, IframeModule, KeyboardModule, MenuModule, PlayerModule, PopupModule],
})
export class CoreModule {}
